import React, { Component } from "react";
import customFetch from "./apicall/api";
import { withRouter } from "react-router";
import { ThemeContext } from "../context/IsLoader";
import { logout, generateId } from "./Utility";
import "../assets/css/Payments.css";
import Razorpay from "razorpay";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CreditCardOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Select, Space, Tooltip, Typography } from "antd";

const { Option } = Select;

class Payments extends Component {
  static contextType = ThemeContext;
  constructor(props) {
    super(props);
    this.state = {
      isSpin: false,
      modalOpenMobile: false,
      modalOpenOtp: false,
      mobile: "",
      editMobile: false,
      otp: new Array(4).fill(""),
      hhnumber: "",
      inputdata: "",
      mobile_no: "",
      result: {},
      hhId: "",
      amount: "",
      name: "",
      hhfullname: "",
      showAmtCard: false,
      errorMessage: "",
      isSuccess: false,
      paymentId: "",
      row_id: "",
    };
  }
  componentDidMount() {}

  handleSearch() {
    this.setState({
      isSpin: true,
    });
    customFetch(
      `${process.env.REACT_APP_CLOUD +
        "/" +
        process.env.REACT_APP_ENVIRONMENT +
        "_latest_household_payment"}`,
      {
        method: "POST",
        headers: {
          //  "api-token": localStorage.getItem('api_token'),
          //   component_id: generate_Id1,
          //   apiId: generate_Id,
          //   api_group_id: "",
          //   component: this.constructor.name,
          //   user_id: localStorage.getItem("in_userid"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          [this.state.searchtype]: this.state.inputdata.toString(),
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        //console.log("json", data);
        if (
          data.error === "Household not found" ||
          this.state.hhnumber === ""
        ) {
          this.setState({
            showAmtCard: false,
            errorMessage: data.error,
          });
        }
        const handleReplace = data.name
          .split(" ")
          .map(
            (word) =>
              word[0] + "*".repeat(word.length - 2) + word[word.length - 1]
          )
          .join(" ");

        this.setState({
          name: handleReplace,
          amount: data.amount_to_collect,
          hhnumber: data.hhnumber,
          hhnumberOnCard: data.hhnumber,
          showAmtCard: true,
          errorMessage: "",
          isSpin: false,
          mobile_no: data.mobile_no,
          hhfullname: data.name,
          row_id: data.row_id,
        });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isSpin: false });
      });
  }
  passrow_id() {
    customFetch(
      `${process.env.REACT_APP_CLOUD +
        "/" +
        process.env.REACT_APP_ENVIRONMENT +
        "_update_row_flag_payment"}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          row_id: this.state.row_id,
        }),
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log("json", data);
        this.setState({ row_id: "" });
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isSpin: false });
      });
  }

  handleChangePassword() {
    alert("a");
  }

  maskNumber(number) {
    // Mask the first 6 digits and keep the last 4 digits intact
    return "xxxxxx" + number.slice(-4);
  }

  moveToNextInput(currentInput, nextInputId) {
    if (currentInput.value.length === 1) {
      document.getElementById(nextInputId).focus();
    }
  }

  // Handle input change
  handleChange = (element, index) => {
    const value = element.value;
    if (isNaN(value)) return;
    const newOtp = [...this.state.otp];
    newOtp[index] = value;
    this.setState({
      otp: newOtp,
    });

    // Move to the next input
    if (element.nextSibling && value) {
      element.nextSibling.focus();
    }
  };

  // Handle form submit
  handleSubmit = (e) => {
    e.preventDefault();
    alert(`Entered OTP: ${this.state.otp.join("")}`);
  };

  loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  callRazorpaySDK = async () => {
    this.setState({ isSpin: true });
    const formdata1 = new FormData();
    formdata1.append("household_id", this.state.hhnumber);
    formdata1.append("amount", parseInt(this.state.amount));
    // Make an API call to your backend to create an order
    await customFetch(process.env.REACT_APP_URL + "/order/create", {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        // "Authorization": `Basic ${credentials}`,  // Add Basic Auth header
        // // Any other headers you might need
        // "Access-Control-Allow-Origin": '*',
      },
      body: formdata1,
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        this.setState({
          result: response[0],
          isSpin: false,
        });
        //console.log("cre", response[0], this.state.result);
        const { amount, id: order_id, currency } = response[0];
        const options = {
          key: process.env.REACT_APP_RZP_KEY, // Enter the Key ID generated from the Dashboard
          amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          currency: currency,
          name: "Navadhan Capital Private Limited", //your business name
          description: this.state.hhnumber,
          image:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQKoCImzRGYjIXBOLGvldo_SKveUrqk1OiXhIg90e5XGQ&s",
          order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          handler: (response) => {
            // Success response from Razorpay
            const paymentData = {
              paymentId: response.razorpay_payment_id,
              order_id: response.razorpay_order_id,
              signature: response.razorpay_signature,
            };

            // Update the state with the payment details
            this.setState({
              paymentId: response.razorpay_payment_id,
              isSuccess: true,
            });

            //alert('Payment Successful');
            console.log("Payment successful:", paymentData);
            this.passrow_id();
          },
          prefill: {
            //We recommend using the prefill parameter to auto-fill customer's contact information, especially their phone number
            name: this.state.hhfullname, //your customer's name
            email: "",
            contact: this.state.mobile_no, //Provide the customer's phone number for better conversion rates
          },
          notes: {
            hhnumber: this.state.hhnumber,
          },
          theme: {
            color: "#3399cc",
          },
          method: {
            card: false, // Hide card option
            netbanking: false, // Hide netbanking option
            wallet: false, // Hide wallet option
            bank_transfer: false,
          },
        };

        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function(response) {
          console.error("Payment failed:", response.error);
        });
        paymentObject.open();
      })
      .catch((error) => console.error("Network Error:", error));
  };

  render() {
    return (
      <>
        {this.state.isSpin ? (
          <div className="spinbody">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="200px"
              width="200px"
              viewBox="0 0 200 200"
              class="pencil"
            >
              <defs>
                <clipPath id="pencil-eraser">
                  <rect height="30" width="30" ry="5" rx="5"></rect>
                </clipPath>
              </defs>
              <circle
                transform="rotate(-113,100,100)"
                stroke-linecap="round"
                stroke-dashoffset="439.82"
                stroke-dasharray="439.82 439.82"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                r="70"
                class="pencil__stroke"
              ></circle>
              <g transform="translate(100,100)" class="pencil__rotate">
                <g fill="none">
                  <circle
                    transform="rotate(-90)"
                    stroke-dashoffset="402"
                    stroke-dasharray="402.12 402.12"
                    stroke-width="30"
                    stroke="hsl(223,90%,50%)"
                    r="64"
                    class="pencil__body1"
                  ></circle>
                  <circle
                    transform="rotate(-90)"
                    stroke-dashoffset="465"
                    stroke-dasharray="464.96 464.96"
                    stroke-width="10"
                    stroke="hsl(223,90%,60%)"
                    r="74"
                    class="pencil__body2"
                  ></circle>
                  <circle
                    transform="rotate(-90)"
                    stroke-dashoffset="339"
                    stroke-dasharray="339.29 339.29"
                    stroke-width="10"
                    stroke="hsl(223,90%,40%)"
                    r="54"
                    class="pencil__body3"
                  ></circle>
                </g>
                <g
                  transform="rotate(-90) translate(49,0)"
                  class="pencil__eraser"
                >
                  <g class="pencil__eraser-skew">
                    <rect
                      height="30"
                      width="30"
                      ry="5"
                      rx="5"
                      fill="hsl(223,90%,70%)"
                    ></rect>
                    <rect
                      clip-path="url(#pencil-eraser)"
                      height="30"
                      width="5"
                      fill="hsl(223,90%,60%)"
                    ></rect>
                    <rect height="20" width="30" fill="hsl(223,10%,90%)"></rect>
                    <rect height="20" width="15" fill="hsl(223,10%,70%)"></rect>
                    <rect height="20" width="5" fill="hsl(223,10%,80%)"></rect>
                    <rect
                      height="2"
                      width="30"
                      y="6"
                      fill="hsla(223,10%,10%,0.2)"
                    ></rect>
                    <rect
                      height="2"
                      width="30"
                      y="13"
                      fill="hsla(223,10%,10%,0.2)"
                    ></rect>
                  </g>
                </g>
                <g
                  transform="rotate(-90) translate(49,-30)"
                  class="pencil__point"
                >
                  <polygon
                    points="15 0,30 30,0 30"
                    fill="hsl(33,90%,70%)"
                  ></polygon>
                  <polygon
                    points="15 0,6 30,0 30"
                    fill="hsl(33,90%,50%)"
                  ></polygon>
                  <polygon
                    points="15 0,20 10,10 10"
                    fill="hsl(223,10%,10%)"
                  ></polygon>
                </g>
              </g>
            </svg>
          </div>
        ) : (
          ""
        )}
        <div
          className={
            this.state.isSuccess
              ? "modal fade bd-example-modal-sm show mymodal"
              : "modal fade bd-example-modal-sm"
          }
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
          id="confirm-box"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content cdb-confirm-box col-sm-12 _success">
              <button
                type="button"
                className="close text-right"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  this.setState({ isSuccess: false });
                  window.location.reload();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
              <div class="row justify-content-center">
                <div class="col-md-5">
                  <div>
                    <i>
                      <CheckCircleOutlined />
                    </i>
                    <h2> Your payment was successful </h2>
                    <div className="message">{this.state.paymentId}</div>
                    <p> Thank you for your payment.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container textcenter">
          <div className="text-center mb-4">
            <img src={require("../assets/images/Navadhan_Logo.png")} height="100px" width="150px"/>
          </div>
          <section className={"container-payments"}>
            {/* <div><select className="float-right"></select></div> */}
            <div className="form-row">
              <div className="col-md-12">
                {/* <label htmlFor="household" className={"label"}>
                  Household Number
                </label> */}
              </div>
              <div className="col-md-4">
                <select
                  className="form-control input"
                  onChange={(e) =>
                    this.setState({ searchtype: e.target.value, inputdata: "" })
                  }
                >
                  <option value="">Select</option>
                  <option value="hhnumber">Household Number</option>
                  {/* <option value="aadhaar">Aadhar card</option> */}
                  {/* <option value="pan">Pan card</option> */}
                  <option value="registered_mobile_no">
                    Registered Mobile Number
                  </option>
                  {/* <option value="communication_mobile_no">
                    Communication Mobile Number
                  </option> */}
                </select>
              </div>
              <div className="col-md-4">
                <input
                  id="username"
                  type="text"
                  className={"form-control input"}
                  // placeholder="Enter Household Number"
                  // aria-label="Enter Household Number"
                  value={this.state.inputdata}
                  onChange={(e) => {
                    this.setState({
                      inputdata: e.target.value,
                    });
                  }}
                />
                <div className="text-danger font-weight-bold">
                  {this.state.errorMessage}
                </div>
              </div>
              <div className="col-md-4 text-center">
                <button
                  type="button"
                  className={"btn button font-weight-bold sbmt-btn"}
                  onClick={() => this.handleSearch()}
                  disabled={!this.state.inputdata || !this.state.searchtype}
                >
                  Search
                </button>
              </div>
            </div>
          </section>

          {/* {this.state.showAmtCard && (
            <div
              className="card_block"
              // key={key}
              // onClick={() => this.showDetail()}
            >
              <div id="OvernightNameAmount" className="card_amount">
                <div>
                  <p className="card_amount_name font-weight-bold">
                    {this.state.name}
                  </p>
                  <p className="card_amount_name">
                    {this.state.hhnumberOnCard}
                  </p>
                </div>
                <span className="font-weight-bold amtinCard">
                  Rs. {this.state.amount}
                </span>
              </div>
              <div id="OvernightDate" className="card_footer text-center">
                <span className="">
                  <button
                    id="rzp-button1"
                    className="btn font-weight-bold"
                    onClick={() => this.callRazorpaySDK()}
                  >
                    Pay
                  </button>
                </span>
              </div>
            </div>
          )} */}
          {this.state.showAmtCard && (
            <div class="col-lg-4 col-md-12 py-5">
              <div></div>
              <div class="purchase-section flex-fill flex-vertical">
                <ul class="purchase-props">
                  <li class="flex-between">
                    <span>{this.state.name}</span>
                  </li>
                </ul>
              </div>
              <div class="separation-line"></div>
              <div class="total-section flex-between flex-vertical-center">
                <div class="flex-fill flex-vertical">
                  <div class="total-label f-secondary-color">
                    You have to Pay
                  </div>
                  <div>
                    <strong>Rs. {this.state.amount}</strong>
                  </div>
                </div>
                <button
                  className="btn btn-primary"
                  onClick={() => this.callRazorpaySDK()}
                >
                  <CreditCardOutlined /> Pay Now
                </button>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(Payments);
