import { Button, message, Space } from "antd";
import moment from "moment";
import customFetch from "./apicall/api";
export function logout(otherscreen = null, no_of_page = null) {
  //const formdata = new FormData();
  // console.log(api_token);
  let api_token = localStorage.getItem("api_token");
  console.log("api_token", api_token);
  customFetch(process.env.REACT_APP_URL + "/new/logout", {
    method: "POST",
    headers: { "api-token": localStorage.getItem("api_token") },
    //body: formdata,
  })
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      // console.log(data);
      localStorage.clear();
      window.location.href = "/";
    })
    .catch((error) => {
      console.log(error);
    });
}

var beforeload = new Date().getTime();
var ms;
export function navtime() {
  // let totime = 0;
  // totime = performance.now().toFixed(0) + " ms";
  // console.log("Nav Time:", performance.now());
  // return totime;
  // console.log("beforeload:", beforeload);
  var afterload = new Date().getTime();
  ms = afterload - beforeload;
  beforeload = new Date().getTime();
  return ms + " ms";
}
// export function navtime() {
//   let data;

//   describe("navigation", () => {
//     beforeAll(async () => {
//       data = await navigation();
//     });
//     [
//       "connect_end",
//       "connect_start",
//       "decoded_body_size",
//       "domain_lookup_end",
//       "domain_lookup_start",
//       "dom_complete",
//       "dom_content_loaded_event_end",
//       "dom_content_loaded_event_start",
//       "dom_interactive",
//       "duration",
//       "encoded_body_size",
//       "fetch_start",
//       "load_event_end",
//       "load_event_start",
//       "redirect_end",
//       "redirect_start",
//       "request_start",
//       "response_end",
//       "response_start",
//       "secure_connection_start",
//       "transfer_size",
//       "unload_event_end",
//       "unload_event_start",
//       "worker_start",
//     ].forEach((event) =>
//       it(`${event} == number`, () => {
//         expect(data).to.have.property(event);
//         expect(data[event]).to.be.a("number");
//       })
//     );
//     ["dom_loading", "navigation_start"].forEach((event) =>
//       it(`${event} == undefined`, () => {
//         expect(data).to.have.property(event);
//         expect(data[event]).to.be.a("undefined");
//       })
//     );
//   });
//   //console.log("Navigation:", data);
//   //return data;
// }

const buildFilter = (filter) => {
  let query = {};
  for (let keys in filter) {
    if (
      filter[keys].constructor === Object ||
      (filter[keys].constructor === Array && filter[keys].length > 0)
    ) {
      query[keys] = filter[keys];
    }
  }
  return query;
};

export function generateId(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

// Our new filter function
export const filterData = (data, query, keysWithMinMax = []) => {
  //let truth_value;
  query = buildFilter(query);
  const filteredData = data.filter((item) => {
    for (let key in query) {
      if (item[key] === undefined) {
        return false;
      } else if (keysWithMinMax.includes(key)) {
        let truth_value = query[key].some((range) => {
          if (
            (range["min"] !== null && item[key] < range["min"]) ||
            (range["max"] !== null && item[key] > range["max"])
          ) {
            return false;
          } else {
            return true;
          }
        });
        if (truth_value == false) return truth_value;
      } else if (
        !query[key].some((value) => String(item[key]).includes(value))
      ) {
        return false;
      }
    }
    return true;
  });
  return filteredData;
};

// query = buildFilter(filter);
// console.log(JSON.stringify(query));
// result = filterData(data, query);
// console.log(JSON.stringify(result, null, 4))

// filter = {
//   type: [
//       'Apartment',
//   ],
//   saleType: [
//       'For Rent',
//   ],
//  listPrice: [
//   {min: null,
//     max: 1}
//  ],
//   washerDryerInUnit: [
//       true,
//   ],
// };

// Check API input are null or not if null then logout
export function chkapiinput(arr) {
  for (let index = 0; index < arr.length; index++) {
    const element = arr[index];
    // console.log("aaaa", arr[index]);
    if (arr[index] === null || arr[index] === "") {
      Popup();
      return false;
      break;
    }
  }
}
export function Popup() {
  message.open({
    type: "error",
    content: "You are Logout. Login again",
    className: "custom-class",
    duration: 10,
    style: {
      marginTop: "20vh",
    },
  });
}

export function setDateTime(date, str) {
  var sp = str.split(":");
  date.setHours(parseInt(sp[0], 10));
  date.setMinutes(parseInt(sp[1], 10));
  date.setSeconds(parseInt(sp[2], 10));
  return date;
}
export function checkTime() {
  var d = new Date();
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
  let mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
  var current, currentend;
  var mainstime = moment(
    process.env.REACT_APP_maintaenance_start,
    "DD/MM/YYYY HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");
  var mainendtime = moment(
    process.env.REACT_APP_maintaenance_end,
    "DD/MM/YYYY HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");

  // if (moment("01/" + mo + "/" + ye).format("ddd") === "Fri") {
  //   current = new Date("01/" + mo + "/" + ye);
  //   startTime = setDateTime(new Date(current), "18:00:00");
  //   //currentend = new Date("02/" + mo + "/" + ye);
  //   currentend = new Date("02/" + mo + "/" + ye);
  //   endTime = setDateTime(new Date(currentend), "2:00:00");
  // } else {
  //   current = new Date("02/" + mo + "/" + ye);
  //   startTime = setDateTime(new Date(current), "21:00:00");
  //   //currentend = new Date("03/" + mo + "/" + ye);
  //   currentend = new Date("03/" + mo + "/" + ye);
  //   endTime = setDateTime(new Date(currentend), "2:00:00");
  // }

  var startTime = new Date(mainstime);
  var endTime = new Date(mainendtime);
  if (d > startTime.getTime() && d < endTime.getTime()) {
    // this.state.time_flag = 1;
    // this.setState({ isSpin: false });
    //alert("y");

    var stime = moment(mainstime).format("hh a");
    var etime = moment(mainendtime).format("hh a");
    var message =
      "This facility will not be available between " + stime + " to " + etime;
    return message;
  } else {
    //alert("N");
    //this.state.time_flag = 0;
    return false;
  }
}
export function checkBanner() {
  var mainsdate = moment(
    process.env.REACT_APP_maintaenance_start,
    "DD/MM/YYYY"
  ).format("YYYY-MM-DD HH:mm:ss");
  var mainstime = moment(
    process.env.REACT_APP_maintaenance_start,
    "DD/MM/YYYY HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");
  var mainendtime = moment(
    process.env.REACT_APP_maintaenance_end,
    "DD/MM/YYYY HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");
  //return mainsdate;
  // if (!moment().isAfter(mainsdate)) {
  //   alert("future");
  // }
  if (
    moment(mainsdate).isSame(moment(), "day") == true ||
    !moment().isAfter(mainsdate)
  ) {
    var stime = moment(mainstime).format("dddd hh:mm a");
    var etime = moment(mainendtime).format("dddd hh:mm a");

    var message =
      stime +
      " से " +
      etime +
      " तक कलेक्शन ऐप डाउन रहेगा. आप कलेक्शन की एंट्री नहीं कर पायेंगे.";
    return message;
  } else {
    return false;
  }
}

export function bannertimer() {
  var mainsdate = moment(
    process.env.REACT_APP_maintaenance_start,
    "DD/MM/YYYY HH:mm:ss"
  ).format("YYYY-MM-DD HH:mm:ss");
  var now = new Date().getTime();
  var t = moment(mainsdate).valueOf() - moment().valueOf();
  var days = Math.floor(t / (1000 * 60 * 60 * 24));
  var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((t % (1000 * 60)) / 1000);
  //this.setState({days, minutes, hours, seconds})
  if (t < 0) {
    clearInterval(this.x);
    //this.setState({ days: 0, minutes: 0, hours: 0, seconds: 0, time_up: "TIME IS UP" })
  }
  return (
    days +
    " Day, " +
    hours +
    " Hours, " +
    minutes +
    " Minutes, " +
    seconds +
    " Seconds"
  );
}

// consolidate data for report
// const options = ['zone_id','division_id','cluster_name','branch_id'];
export function consolidate(basedata, option) {
  const formatter = new Intl.NumberFormat("en-IN");
  let indexedData = {};
  if (option != "") {
    basedata.forEach((row) => {
      let index = row[option];
      if (indexedData[index]) {
        let previousValue = indexedData[index];
        indexedData[index] = {
          ...previousValue,
          collected: formatter.format(
            parseFloat(previousValue["collected"]) +
              parseFloat(row["collected"].replaceAll(",", ""))
          ),
          deposited: formatter.format(
            parseFloat(previousValue["deposited"]) +
              parseFloat(row["deposited"].replaceAll(",", ""))
          ),
          opening_balance: formatter.format(
            parseFloat(previousValue["opening_balance"]) +
              parseFloat(row["opening_balance"].replaceAll(",", ""))
          ),
          closing_balance: formatter.format(
            parseFloat(previousValue["closing_balance"]) +
              parseFloat(row["closing_balance"].replaceAll(",", ""))
          ),
          fcm_approval_pending: formatter.format(
            parseFloat(previousValue["fcm_approval_pending"]) +
              parseFloat(row["fcm_approval_pending"].replaceAll(",", ""))
          ),
          finops_approval_pending: formatter.format(
            parseFloat(previousValue["finops_approval_pending"]) +
              parseFloat(row["finops_approval_pending"].replaceAll(",", ""))
          ),
          fcm_approved_amount: formatter.format(
            parseFloat(previousValue["fcm_approved_amount"]) +
              parseFloat(row["fcm_approved_amount"].replaceAll(",", ""))
          ),
        };
      } else {
        indexedData[index] = {
          ...row,
          zone_id: 0,
          zone: "",
          division_id: 0,
          division: "",
          cluster_name: "",
          branch_code: "",
          branch_id: 0,
          branch_name: "",
          product_group_name: "All",
          state_name: "All",
          id: 424,
          userid: 0,
          username: "",
          usertype: "All",
        };
      }
      if (option == "branch_id") {
        indexedData[index]["branch_id"] = row["branch_id"];
        indexedData[index]["branch_name"] = row["branch_name"];
        indexedData[index]["branch_code"] = row["branch_code"];
        indexedData[index]["cluster_name"] = row["cluster_name"];
        indexedData[index]["division_id"] = row["division_id"];
        indexedData[index]["division"] = row["division"];
        indexedData[index]["zone_id"] = row["zone_id"];
        indexedData[index]["zone"] = row["zone"];
      }
      if (option == "zone_id") {
        indexedData[index]["zone_id"] = row["zone_id"];
        indexedData[index]["zone"] = row["zone"];
      }
      if (option == "division_id") {
        indexedData[index]["division_id"] = row["division_id"];
        indexedData[index]["division"] = row["division"];
        indexedData[index]["zone_id"] = row["zone_id"];
        indexedData[index]["zone"] = row["zone"];
      }
      if (option == "cluster_name") {
        indexedData[index]["cluster_name"] = row["cluster_name"];
        indexedData[index]["division_id"] = row["division_id"];
        indexedData[index]["division"] = row["division"];
        indexedData[index]["zone_id"] = row["zone_id"];
        indexedData[index]["zone"] = row["zone"];
      }
    });
    return Object.values(indexedData);
  } else {
    return basedata;
  }
}
